.hero_wrapper {
  background-image: url("/public/images/hero-background.png");
  background-color: black;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  font-family: "Roboto", sans-serif;
  
}


.scroll_nav {
  position: fixed;
  border-bottom: 1px solid rgba(182, 179, 179, 0.466);
  box-shadow: 0px 0px 12px #ffffff42;
  background-color: #0f0035;
  font-family: "Roboto", sans-serif;
  padding: 8px 0px;
  transition: all 0.5 ease-in;
}

.default_nav {
  border-bottom: 1px solid rgba(182, 179, 179, 0.466);
  box-shadow: 0px 0px 12px #ffffff42;
  background-color: rgba(0, 0, 0, 0.363);
  font-family: "Roboto", sans-serif;
  padding: 20px 0px;
  transition: all 0.5 ease-in;
}

.heroBtn1 {
  background-color: #771ce8;
  padding: 0.3vw 3vw;
  border: 1px solid white;
  border-radius: 30px;
  transition: all 0.3s ease-in;
}
.heroBtn1:hover {
  background-color: #00000031;
  padding: 0.3vw 3vw;
  border: 1px solid white;
  border-radius: 30px;
}

.heroBtn2 {
  background-color: #00000031;
  padding: 0.3vw 3vw;
  border: 1px solid white;
  border-radius: 30px;
  transition: all 0.3s ease-in;
}
.heroBtn2:hover {
  background-color: #771ce8;
  padding: 0.3vw 3vw;
  border: 1.5px solid white;
  border-radius: 30px;
}


.navbar_toggler_icon{
  background-color: rgba(119, 0, 255, 0.358);
  color: white;
}