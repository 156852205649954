.colored_text{
    color: #6E00E1;
    font-weight: bold;

}
.portfolio_title{
    font-family: "Roboto", sans-serif;
}

.card_wrapper{
    transition: all 0.3s ease-in;
}

.card_wrapper:hover{
      /* background-color: #6d00e1 !important; */
}


.card_wrapper {
  position: relative;
 
}

.cardImg {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #6d00e16c;
}

.card_wrapper:hover .overlay {
  opacity: 1;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}